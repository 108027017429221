<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-forgot-on"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})`, backgroundPositionY: 'center' }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  パスワード再設定
                </h3>
              </div>
              <div class="form-group">
                <label>新しいパスワード</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label>新しいパスワードを確認</label>
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  name="cpassword"
                  ref="rcpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  パスワードを再設定する
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, RESET_PASSWORD } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ResetPassword",
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "getError"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/logos/rogo.png"
      );
    },
    queryParam() {
        return this.$route.query
    }
  },

  mounted() {
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    const strongPassword = function() {
      return {
        validate: function (input) {
            const value = input.value;
            if (value === '') {
                return {
                    valid: true,
                };
            }

            // Check the password strength
            if (value.length < 8 || value.length > 32) {
                return {
                    valid: false,
                    // message: 'The password must be more than 8 characters long',
                };
            }

            // The password does not contain any uppercase character
            if (value === value.toLowerCase()) {
                return {
                    valid: false,
                    // message: 'The password must contain at least one upper case character',
                };
            }

            // The password does not contain any uppercase character
            if (value === value.toUpperCase()) {
                return {
                    valid: false,
                    // message: 'The password must contain at least one lower case character',
                };
            }

            // The password does not contain any digit
            if (value.search(/[0-9]/) < 0) {
                return {
                    valid: false,
                    // message: 'The password must contain at least one digit',
                };
            }

            if (value.search(/[!@#$%^&*]/) < 0) {
                return {
                    valid: false,
                };
            }

            return {
                valid: true,
            };
        },
    };
    };

    this.fv2 = formValidation(forgot_form, {
      fields: {
        password: {
          validators: {
            notEmpty: {
              message: this.$t('required', {field: "新しいパスワード"}),
            },
            checkPassword: {
              message: this.$t('validatePassword')
            },
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t('required', {field: "新しいパスワードを確認"}),
            },
            identical: {
              compare: function() {
                return forgot_form.querySelector('[name="password"]').value;
              },
              message: this.$t('confirmPassword'),
            }
          }
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    })
    .registerValidator('checkPassword', strongPassword);;

    this.fv2.on("core.form.valid", async () => {
        const password = this.$refs.rpassword.value;
        const cpassword = this.$refs.rcpassword.value;
        const token = this.queryParam.token;

         // clear existing errors
        this.$store.dispatch(LOGOUT);

       const data = await this.$store.dispatch(RESET_PASSWORD, {password: password, password_confirmation: cpassword, token})
       if (data.code == SUCCESS) {
           this.goToLogin();
           this.notifySuccess(this.$t('reset_success'));
       } else {
           var arr = [];
           if (data.data.password) {
               arr.push(data.data.password[0]);
           }
           if (data.data.token) {
               arr.push(data.data.token[0])
           }
           arr.forEach((value, key) => {
               setTimeout(() => {
                    this.notifyError(value)
               }, 200);
           });
       }
    });
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  }
};
</script>
